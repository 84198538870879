import axios from "./interceptor";

const http = {
  get: async (
    url,
    data = {},
    headers = {},
    config = { responseType: "json" }
  ) =>
    axios.get(url, {
      params: data,
      headers: headers,
      responseType: config.responseType,
    }),
  post: async (
    url,
    data = {},
    headers = {},
    config = { responseType: "json" }
  ) =>
    axios.post(url, data, {
      headers: headers,
      responseType: config.responseType,
    }),

  post1: async (url, data = {}, config = {}) =>
    axios.post(url, data, {
      signal: config.signal,
      responseType: "json",
    }),
  put: async (
    url,
    data = {},
    headers = {},
    config = { responseType: "json" }
  ) =>
    axios.put(url, data, {
      headers: headers,
      responseType: config.responseType,
    }),
  delete: async (
    url,
    data = {},
    headers = {},
    config = { responseType: "json" }
  ) =>
    axios.delete(url, {
      data: data,
      headers: headers,
      responseType: config.responseType,
    }),
};

export default http;
