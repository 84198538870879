import Axios from "axios";
import { store } from "../store";
import NotificationService from "./notification.service";
//import Config from "../environment";
import { environment } from "../environment.js";
import UserService from "./user.service";
import { SET_SERVICE_LOADER } from "../store/constants";

const axios = Axios.create({
  baseURL: environment.react_app_service, //Config.SERVICE_URL,
});

axios.interceptors.request.use(
  async (config) => {
    const { auth, app } = store.getState();
    const interceptedConfig = config;
    interceptedConfig.headers["Content-type"] = "application/json";
    // interceptedConfig.headers["Access-Control-Allow-Origin"] = "*";

    if (auth.authToken !== null) {
      interceptedConfig.headers.Authorization = `Bearer ${auth.authToken}`;
    }
    store.dispatch({
      type: SET_SERVICE_LOADER,
      payload: true,
    });
    return interceptedConfig;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    // if (response.headers["client-id"]) {
    //   console.log(response.headers["client-id"]);
    // }
    store.dispatch({
      type: SET_SERVICE_LOADER,
      payload: false,
    });
    return response;
  },
  async (error) => {
    if (error.response) {
      store.dispatch({
        type: SET_SERVICE_LOADER,
        payload: false,
      });
      if (error?.response?.status === 401) {
        // store.dispatch({ type: "USER_LOGOUT" });
        // store.dispatch({
        //   type: SET_SERVICE_LOADER,
        //   payload: false,
        // });
        await UserService.updateToken();
        // NotificationService.showErrorMessage(
        //   error.response.data.error ||
        //     "Session Expired. Please try to login again"
        // );
      } else {
        NotificationService.showErrorMessage(
          error.response.data || "Something went wrong. Please contact to admin"
        );
      }
    } else {
      if (error?.name !== "CanceledError") {
        NotificationService.showErrorMessage(
          "Something went wrong.Please contact to Admin"
        );
      }
    }
    return Promise.reject(error.response || error.message || error.request);
  }
);

export default axios;
