const ErrorMsg = {
  getNotificationsListFailure:
    "Failed to get the notifications. Please try again.",
  addNotificationFailure: "Failed to add notification. Please try again.",
  updateNotificationFailure: "Failed to update notification. Please try again.",
  getLookupDetailFailure: "Failed to get Look up details. Please try again.",
  addLookupFailure: "Failed to add Look up. Please try again.",
  getLocationListFailure: "Failed to get all the locations. Please try again.",
  getLocationDetailsFailure: "Failed to get location detail. Please try again.",
  getLocationswithFunctionTypeFailure:
    "Failed to get location with this function type. Please try again.",
  addLocationFailure: "Failed to add location. Please try again.",
  getProcurementFailure: "Failed to get procurements. Please try again.",
  addProcurementFailure: "Failed to add procurement. Please try again.",
  getClaimFailure: "Failed to get claims. Please try again.",
  addClaimFailure: "Failed to add claim. Please try again.",
  fetchSegmentsFailure: "Failed to fetch segments. Please try again.",
  addSegmentFailure: "Failed to add segment. Please try again.",
  getAllGroupsFailure: "Failed to get groups. Please try again.",
  addGroupFailure: "Failed to add group. Please try again.",
  createZoneFailure: "Failed to create zone. Please try again.",
  addOrUpdateZoneMapFailure: "Failed to add or update zone. Please try again.",
  deleteZipFailure: "Failed to delete zip. Please try again.",
  deleteGroupFailure: "Failed to delete group. Please try again.",
  getCommonDetailsFailure: "Failed to get data. Please try again.",
  getCompanyDetailFailure: "Failed to get company details. Please try again.",
  addCompanyFailure: "Failed to add company. Please try again.",
  getCompanyListFailure: "Failed to get all the Companies. Please try again.",
  getCalenderListFailure: "Failed to get Calender list. Please try again.",
  addCalenderFailure: "Failed to add Calender. Please try again.",
  getSystemOptionsFailure: "Failed to get system options. Please try again.",
  addSystemOptionFailure: "Failed to add system option. Please try again.",
  getTransportOptionsListFailure:
    "Failed to get transport options. Please try again.",
  addTransportOptionFailure:
    "Failed to add transport option. Please try again.",
  getTermsFailure: "Failed to get terms. Please try again.",
  addTermFailure: "Failed to add term. Please try again.",
  getDocumentListFailure: "Failed to get all the documents. Please try again.",
  addDocumentFailure: "Failed to add the document. Please try again.",
  addPeriodsFailure: "Failed to add period. Please try again.",
  getPeriodDetailsFailure: "Failed to get period details. Please try again.",
  getPartnerListFailure: "Failed to get all the partners. Please try again.",
  getAutoLoadListFailure: "Failed to get the autoloads. Please try again.",
  addAutoLoadFailure: "Failed to add the autoload. Please try again.",
  getUserNotificationFailure:
    "Failed to get user notifications. Please try again.",
  getVendorInfoFailure: "Failed to get Vendor information. Please try again.",
  getUserDetailsFailure: "Failed to get user detail. Please try again.",
  addUserFailure: "Failed to add user. Please try again.",
  fetchByUsernameFailure:
    "Failed to fetch detail by user name. Please try again.",
  getUserDetailsByIdFailure: "Failed to get user detail. Please try again.",
  resetPasswordFailure: "Failed to reset password. Please try again.",
  getUserListFailure: "Failed to get users. Please try again.",
  addMappingFailure: "Failed to add mapping. Please try again.",
  getItemMapListFailure: "Failed to get item map list. Please try again.",
  getClientListFailure: "Failed to get all the clients. Please try again.",
  getItemCreateListFailure: "Failed to get item create list. Please try again.",
  getItemUnMappedListFailure:
    "Failed to get item unmapped list. Please try again.",
  getItemCodeDetailsItemIdFailure:
    "Failed to get item code detail. Please try again.",
  getItemCreateDetailsFailure:
    "Failed to get item create detail. Please try again.",
  getLookupDetailsFailure: "Failed to get lookup details. Please try again.",
  postItemCreateFailure: "Failed to add item. Please try again.",
  itemMasterGridFileDownloadFailure:
    "Failed to download item master grid file. Please try again.",
  itemMapUploadSummaryFailure: "Failed to get summary. Please try again.",
  itemMapErrorFileDownloadFailure:
    "Failed to download item map. Please try again.",
  itemCreateUploadSummaryFailure: "Failed to get summary. Please try again.",
  itemCreateErrorFileDownloadFailure:
    "Failed to download item. Please try again.",
  fetchVendorLoactionsFailure:
    "Failed to fetch vendor locations. Please try again.",
  getSegmentListFailure: "Failed to get all segments. Please try again.",
  getAllShippingDataFailure:
    "Failed to get all shipping data. Please try again.",
  getAllReceivingDataFailure:
    "Failed to get all receiving data. Please try again.",
  defaultaccesorialsFailure: "Failed to get accesorials. Please try again.",
  addAccesorialsFailure: "Failed to add accesorials. Please try again.",
  UpdateAccesorialsFailure: "Failed to update accesorials. Please try again.",
  linehaulsFailure: "Failed to get linehauls. Please try again.",
  AddOrUpdateLinehaulsFailure:
    "Failed to add/update linehaul. Please try again.",
  searchClientFailure: "Failed to search client. Please try again.",
  validatePartnerFailure: "Failed to validate partner. Please try again.",
  addPartnerFailure: "Failed to add partner. Please try again.",
  getAttachmentsFailure: "Failed to get attachments. Please try again.",
  addAttachmentFailure: "Failed to get attachments. Please try again.",
  viewAttachmentFailure: "Failed to view attachment. Please try again.",
  uploadAttachmentFailure: "Failed to upload attachment. Please try again.",
  getActiveBusinessUnitsFailure:
    "Failed to get active business units. Please try again.",
  getBusinessUnitsFailure: "Failed to get business units. Please try again.",
  addBusinessUnitsFailure: "Failed to add business unit. Please try again.",
  getTradingLocationListFailure:
    "Failed to get trading locations. Please try again.",
  getDetailsByPartnerNameFailure:
    "Failed to get details by partner name. Please try again.",
  getNotificationsFailure: "Failed to get notifications. Please try again.",
  addNotificationsFailure: "Failed to add notification. Please try again.",
  getDetailsByPartnerIDFailure:
    "Failed to get details by partner ID. Please try again.",
  modifyPartnerFailure: "Failed to modify partner. Please try again.",
  exportTPListFailure:
    "Failed to export trading partner list. Please try again.",
  getItemCodeDetailsWithRCVIdFailure:
    "Failed to get Item code detail. Please try again.",
  addVendorFailure: "Failed to add vendor. Please try again.",
  vendorUploadSummaryFailure:
    "Failed to upload vendor summary. Please try again.",
  vendorErrorFileDownloadFailure:
    "Failed to download vendor error file. Please try again.",
  getVendorPriceListFailure:
    "Failed to get vendor price list. Please try again.",
  exportVendorPriceListFailure:
    "Failed to export vendor price list. Please try again.",
  getChargesActiveFailure: "Failed to get charges active. Please try again.",
  getChargesInActiveFailure:
    "Failed to get charges inactive. Please try again.",
  addChargsFailure: "Failed to add charges. Please try again.",
  getChargesFailure: "Failed to get charges. Please try again.",
  bybupartnernameFailure:
    "Failed to get data by bupartner name. Please try again.",
  getDefaultChargesFailure: "Failed to get default charges. Please try again.",
  getDefaultChargesActiveFailure:
    "Failed to get default charges active. Please try again.",
  getDefaultChargesInactiveFailure:
    "Failed to get default charges inactive. Please try again.",
  defaultChargesExportFailure:
    "Failed to export default charges. Please try again.",
  defaultChargeUploadSummaryFailure:
    "Failed to upload default charges summary. Please try again.",
  defaultChargeErrorFileDownloadFailure:
    "Failed to download default charge error file. Please try again.",
  getPoListFailure: "Failed to get PO List. Please try again.",
  poExportFailure: "Failed to export po. Please try again.",
  poSearchFailure: "Failed to get searched PO. Please try again.",
  getAllPoNumbersFailure: "Failed to get all PO Numbers. Please try again.",
  getPoLocationListFailure: "Failed to get PO location List. Please try again.",
  getPoDetailsFailure: "Failed to get PO details. Please try again.",
  fetchItemMasterByItemCodeFailure:
    "Failed to fetch item master by item code. Please try again.",
  fetchVendorItemMasterByItemCodeFailure:
    "Failed to fetch vender item master by item code. Please try again.",
  printPDFFailure: "Failed to print PDF. Please try again.",
  getBuyerListFailure: "Failed to get buyer list. Please try again.",
  uploadPOFailure: "Failed to upload PO. Please try again.",
  poUploadSummaryFailure: "Failed to upload po summary. Please try again.",
  poErrorFileDownloadFailure: "Failed to download po. Please try again.",
  rejectPOFailure: "Failed to reject po. Please try again.",
  confirmPOFailure: "Failed to confirm po. Please try again.",
  updatePOFailure: "Failed to update po. Please try again.",
  lineLevelChargeUpdateFailure:
    "Failed to update line level charge. Please try again.",
  cancelPOFailure: "Failed to cancel po. Please try again.",
  defaultTemplateChargesFailure:
    "Failed default template charges. Please try again.",
  latestByVendorFailure: "Failed to fetch latest by vendor. Please try again.",
  defaultHeadChargesFailure:
    "Failed to fetch default head charges. Please try again.",
  claimApproverDropdownListFailure:
    "Failed to fetch claim Approver Dropdown List. Please try again",
  fetchPoReceiptInfoFailure:
    "Failed to fetch PO receipt Information. Please try again.",
  getClaimByClaimNumberFailure:
    "Failed to get claim by claim number. Please try again.",
  getPartnerByIdFailure: "Failed to get partner by id. Please try again.",
  getCarrierDetailsFailure: "Failed to get carrier details. Please try again.",
  getCustomerDetailsByClientIdFailure:
    "Failed to get customer details by client Id. Please try again.",
  getAllShippingPoNumbersFailure:
    "Failed to get all shipping PO numbers. Please try again.",
  getCustomerListFailure: "Failed to get customer list. Please try again.",
  searchShippingPOFailure: "Failed to search shpping PO. Please try again.",
  searchPOByStatusFailure: "Failed to search PO by status. Please try again.",
  getAllItemNumbersFailure: "Failed to fetch item Codes. Please try again.",
};

export default ErrorMsg;
